import React from "react"
import * as Chakra from "@chakra-ui/react"
import { WPImage } from "./gatsby-image-svg-fallback"

interface BlogGalleryProps {
  images: { image: any }[]
}

const BlogGallery = React.forwardRef((props: BlogGalleryProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      borderTop="1px solid currentColor"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
    >
      <Chakra.Box
        width="100%"
        display="flex"
        maxWidth="1800px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="32px"
        marginRight="auto"
        paddingLeft="40px"
        paddingRight="40px"
        flexDirection="column"
        paddingBottom="33px"
        css={{
          "@media (max-width: 767px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "0px",
          },
        }}
      >
        <Chakra.Box
          as="span"
          fontSize="18px"
          fontFamily="BeausiteClassic-Clear"
          lineHeight="20px"
          marginBottom="22px"
        >
          Album
        </Chakra.Box>
        <Chakra.Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          {props?.images &&
            props.images.map(({ image }) => (
              <WPImage
                  marginBottom="30px"
                {...image}
                width="calc(50% - 15px)"
                //height="60vh"
                objectFit="cover"
                css={{
                  "@media (max-width: 767px)": {
                    width: "100%",
                  },
                }}
              />
            ))}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

export default BlogGallery
