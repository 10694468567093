import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql } from "gatsby"
import BlogHero from "../components/blog-hero"
import Section from "../components/section"
import FadeTransition from "../components/transitions/fade-transition"
import BlogGallery from "../components/blog-gallery"
import { Wysiwyg } from "../components/wysiwyg"
import * as htmlEntities from "html-entities"
import { Seo } from "gatsby-plugin-wpgraphql-seo"
import formatDate from "../utils/format-date"

const WpPost = ({ data, pageContext }) => {
  const post = data?.wpPost

  return (
    <Chakra.Box width="100%" flexGrow={1}>
      <Seo
        post={data?.wpPage}
        title={
          data?.wpPage?.seo?.title
            ? htmlEntities.decode(data?.wpPage?.seo?.title)
            : "RED"
        }
      />
      {post?.featuredImage?.node && (
        <BlogHero
          image={post?.featuredImage?.node}
          date={post?.date}
          category={
            post?.categories?.nodes
              ? post.categories.nodes[0].name
              : "Uncategorized"
          }
          tags={post?.tags?.nodes ? post.tags.nodes : []}
        />
      )}
      <Section
        text={`By ${post?.author?.node?.name}`}
        backgroundColor="#F4F2ED"
        zIndex="1"
        borderBottom="1px solid #0008"
      >
        <Chakra.Box
          as="span"
          width="100%"
          fontSize="36px"
          maxWidth="628px"
          fontFamily="Beausite 400"
          lineHeight="43.2px"
          css={{
            "@media (max-width: 767px)": {
              width: "100%",
              height: "auto",
              fontSize: "24px",
              alignSelf: "left",
              textAlign: "left",
              lineHeight: "28.8px",
            },
            "@media (max-width: 479px)": {
              width: "100%",
              maxWidth: "100%",
              fontFamily: "BeausiteClassic-Clear",
            },
          }}
          textTransform="uppercase"
        >
          {post?.title}
          {!post?.featuredImage?.node && (
            <>
              <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                paddingBottom="25px"
                paddingTop="25px"
              >
                <Chakra.Box
                  as="span"
                  fontSize="18px"
                  fontFamily="BeausiteClassic-Clear"
                  lineHeight="21.6px"
                >
                  {post?.categories?.nodes
                    ? post.categories.nodes[0].name
                    : "Uncategorized"}
                </Chakra.Box>
                <Chakra.Box
                  as="span"
                  fontSize="18px"
                  fontFamily="BeausiteClassic-Clear"
                  lineHeight="21.6px"
                >
                  {formatDate(new Date(post.date))}
                </Chakra.Box>
              </Chakra.Box>
              <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                {post?.tags?.nodes
                  && post.tags.nodes.map(({ name }) => (
                      <Chakra.Box
                        as="span"
                        opacity="0.5"
                        fontSize="16px"
                        fontFamily="BeausiteClassic-Clear"
                        lineHeight="24px"
                      >
                        {name}
                      </Chakra.Box>
                    ))}
              </Chakra.Box>
            </>
          )}
        </Chakra.Box>
      </Section>
      {post?.postContent?.sections?.map(
        ({ subtitle, primaryText, extraText }, i) => (
          <Section
            asSticky
            text={subtitle}
            borderTop={i === 0 ? "none" : "1px solid #0008"}
          >
            <Wysiwyg
              letterSpacing="-1%"
              //paddingBottom="30px"
              dangerouslySetInnerHTML={{ __html: primaryText }}
            />
            <Chakra.Accordion width="100%" allowToggle>
              <Chakra.AccordionItem width="100%" border="none">
                {({ isExpanded }) => (
                  <>
                    <Chakra.AccordionPanel
                      fontSize="18px"
                      fontFamily="BeausiteClassic-Clear"
                      lineHeight="26px"
                      paddingTop="0px"
                      paddingLeft="0px"
                      paddingRight="0px"
                      paddingBottom="30px"
                    >
                      <Wysiwyg
                        letterSpacing="-1%"
                        paddingBottom="30px"
                        dangerouslySetInnerHTML={{ __html: extraText }}
                      />
                    </Chakra.AccordionPanel>
                    <Chakra.AccordionButton
                      fontSize="18px"
                      fontFamily="BeausiteClassic-Clear"
                      lineHeight="26px"
                      paddingTop="0px"
                      borderWidth="1px"
                      paddingLeft="0px"
                      paddingRight="0px"
                      paddingBottom="0px"
                      color="#0008"
                      display="inline-block"
                      borderColor="transparent"
                      borderRadius="4px"
                      textAlign="left"
                      _hover={{
                        backgroundColor: "transparent",
                        color: "#000",
                        "&:after": {
                          opacity: "1",
                        },
                      }}
                      _after={{
                        transition: "0.1s",
                        content: "''",
                        position: "absolute",
                        left: "0",
                        width: "100%",
                        opacity: "0",
                        height: "1px",
                        bottom: "0",
                        backgroundColor: "currentColor",
                      }}
                      _active={{
                        backgroundColor: "transparent",
                        color: "#0008",
                      }}
                      _focus={{
                        backgroundColor: "transparent",
                        color: "#0008",
                      }}
                      position="relative"
                      width="fit-content"
                    >
                      <FadeTransition shouldChange={isExpanded.toString()}>
                        {isExpanded ? "Read Less" : "Read More"}
                      </FadeTransition>
                    </Chakra.AccordionButton>
                  </>
                )}
              </Chakra.AccordionItem>
            </Chakra.Accordion>
          </Section>
        )
      )}
      {post?.postContent?.gallery && post?.postContent?.gallery.length > 0 && (
        <BlogGallery images={post?.postContent?.gallery} />
      )}
    </Chakra.Box>
  )
}

export const query = graphql`
  query($id: String) {
    site {
      buildTime
    }
    wpPost(id: { eq: $id }) {
      template {
        templateName
      }
      date
      title
      content
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      author {
        node {
          name
        }
      }
      postContent {
        sections {
          extraText
          primaryText
          subtitle
        }
        gallery {
          image {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

export default WpPost
