import React from "react"
import * as Chakra from "@chakra-ui/react"
import { WPImage } from "./gatsby-image-svg-fallback"
import formatDate from "../utils/format-date"
import { useInView } from "react-intersection-observer"
import mergeRefs from "react-merge-refs"

interface BlogHeroProps {
  image: any
  category: string
  tags: { name: string }[]
  date: string
}

const BlogHero = React.forwardRef((props: BlogHeroProps, ref) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.01,
    initialInView: true,
  })

  return (
    <Chakra.Box
      ref={mergeRefs([ref, inViewRef])}
      width="100%"
      height="70vh"
      display="flex"
      position="relative"
      marginTop="-123px"
      css={{
        "@media (max-width: 991px)": {
          marginTop: "-75px"
        }
      }}
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
    >
      <style>
        {inView && `:root { --hero-text-col: #fff; } .primary-header { background-color: transparent; } .primary-header-border { background-color: transparent; }`}
      </style>
      <WPImage {...props.image} width="100%" height="70vh" objectFit="cover" />
      <Chakra.Box
        top="0px"
        left="50%"
        width="100%"
        height="100%"
        display="flex"
        position="absolute"
        maxWidth="1800px"
        transform="translateX(-50%)"
        alignItems="flex-start"
        marginLeft="auto"
        marginRight="auto"
        flexDirection="column"
      >
        <Chakra.Box
          top="0px"
          left="0px"
          display="flex"
          position="absolute"
          maxWidth="318px"
          alignItems="flex-start"
          paddingTop="153px"
          paddingLeft="40px"
          flexDirection="column"
          css={{
            "@media (max-width: 767px)": {
              paddingLeft: "20px",
            },
          }}
        >
          <Chakra.Box
            width="100%"
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            paddingBottom="25px"
          >
            <Chakra.Box
              as="span"
              fontSize="18px"
              fontFamily="BeausiteClassic-Clear"
              lineHeight="21.6px"
            >
              {props.category}
            </Chakra.Box>
            <Chakra.Box
              as="span"
              fontSize="18px"
              fontFamily="BeausiteClassic-Clear"
              lineHeight="21.6px"
            >
              {formatDate(new Date(props.date))}
            </Chakra.Box>
          </Chakra.Box>
          <Chakra.Box
            width="100%"
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            {props?.tags?.map(({ name }) => (
              <Chakra.Box
                as="span"
                opacity="0.5"
                fontSize="16px"
                maxWidth="120px"
                fontFamily="BeausiteClassic-Clear"
                lineHeight="24px"
              >
                {name}
              </Chakra.Box>
            ))}
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

export default BlogHero
